import PropTypes from 'prop-types';
import { Children, useState } from 'react';
import { NavLink, Link as RouterLink } from 'react-router-dom';

import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Toolbar from '@mui/material/Toolbar';
import Image from 'mui-image';

import { useAuth } from '../auth';
import consts from '../common/consts';
import logo from '../logo.svg';


const Navbar = ({ children }) => {
    const auth = useAuth();

    const handleLogout = async () => {
        auth.onLogout();
    }

    const { siteTitle } = consts.siteMetadata;

    const drawerWidth = 180;

    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const menu = (
        <List>
            {!auth.isLoading && !auth.user &&
                <>
                    <ListItem>
                        <Link component={NavLink} to="/login">Login</Link>
                    </ListItem>
                    <ListItem>
                        <Link component={NavLink} to="/register">Get Started</Link>
                    </ListItem>
                </>
            }
            {auth.user &&
                <ListItem>
                    <Link onClick={handleLogout} sx={{ cursor: 'pointer' }}>Logout</Link>
                </ListItem>
            }
        </List>
    );

    return (
        <Box sx={{ overflowX: 'hidden' }}>
            <Drawer
                variant="persistent"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                SlideProps={{
                    easing: 'ease',
                    timeout: 300
                }}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        backgroundColor: 'rgba(248,245,236,.5)',
                        border: 'none'
                    }
                }}
            >
                <Box
                    onClick={handleDrawerToggle}
                    sx={{
                        typography: 'subtitle1',
                        textAlign: 'center',
                        '& .MuiList-root': {
                            padding: 0
                        },
                        '& .MuiListItem-root': {
                            padding: 0
                        },
                        '& a': {
                            padding: '10px 30px',
                            display: 'block',
                            borderBottom: '1px solid #f8f5ec',
                            color: 'rgba(0, 0, 0, .84)',
                            textDecoration: 'none',
                            fontSize: '18px'
                        }
                    }}
                >
                    <Toolbar sx={{ minHeight: '50px' }} />
                    <Divider sx={{ borderColor: '#f8f5ec' }} />
                    {menu}
                </Box>
            </Drawer >
            <AppBar component="nav" sx={{
                position: { sm: 'static' },
                typography: 'subtitle1',
                color: '#c05b4d',
                boxShadow: { xs: '0 2px 2px #cacaca', sm: 'none' },
                backgroundColor: '#fff',
                transform: 'none',
                transition: 'transform ease 300ms',
                ...(mobileOpen && {
                    transform: `translateX(${drawerWidth}px)`
                })
            }}>
                <Toolbar sx={(theme) => ({
                    width: { sm: '100%' },
                    maxWidth: 'lg',
                    minHeight: '50px',
                    marginLeft: { sm: 'auto' },
                    marginRight: { xs: '50px', sm: 'auto' },
                    borderBottom: { sm: '1px solid #cbd5e0' },
                    [theme.breakpoints.only('xs')]: {
                        padding: 0,
                    }
                })}>
                    <IconButton
                        color="inherit"
                        aria-label="menu"
                        onClick={handleDrawerToggle}
                        sx={{
                            width: '50px',
                            height: '50px',
                            display: { sm: 'none' }
                        }}
                        disableRipple={true}
                    >
                        {mobileOpen ? <CloseIcon /> : <MenuIcon />}
                    </IconButton>
                    <Box sx={(theme) => ({
                        width: { sm: '300px' },
                        [theme.breakpoints.only('xs')]: {
                            display: 'flex',
                            justifyContent: 'center',
                            pt: '6px',
                            flexGrow: 1
                        },
                        '& .logo-wrapper': {
                            width: '140px !important',
                            height: '40px !important'
                        }
                    })}>
                        <Link
                            component={RouterLink}
                            to="/"
                            aria-label={'${siteTitle} - Back to home'}
                        >
                            <Image src={logo} alt={siteTitle} wrapperClassName='logo-wrapper' duration={0} />
                        </Link>
                    </Box>
                    <Box
                        component="nav"
                        sx={{
                            display: { xs: 'none', sm: 'block' },
                            height: '64px',
                            ml: 'auto',
                            '& .MuiList-root': {
                                display: 'flex',
                                height: '100%',
                                padding: 0
                            },
                            '& .MuiListItem-root': {
                                display: 'block',
                                boxSizing: 'content-box',
                                width: 'unset',
                                height: '100%',
                                minHeight: '36px',
                                margin: '0 18px',
                                position: 'relative',
                                padding: 0,
                                '&:has(> a.active)': {
                                    borderBottom: '2px solid rgba(0, 0, 0, .84)'
                                },
                                '&:hover': {
                                    borderBottom: '2px solid #c05b4d !important',
                                    '& a': {
                                        color: '#c05b4d'
                                    }
                                }
                            },
                            '& a': {
                                display: 'flex',
                                height: '100%',
                                alignItems: 'center',
                                color: 'rgba(0, 0, 0, .84)',
                                textDecoration: 'none',
                                fontSize: '18px',
                                '&.active': {
                                    fontWeight: 'bold'
                                }
                            }
                        }}
                    >
                        {menu}
                    </Box>
                </Toolbar>
            </AppBar>
            <main style={{
                padding: '54px 0',
                transform: 'none',
                transition: 'transform ease 300ms',
                ...(mobileOpen && {
                    transform: `translateX(${drawerWidth}px)`
                })
            }}>
                {Children.map(children, child => child)}
            </main>
        </Box >
    )
}

export default Navbar;

Navbar.propTypes = {
    children: PropTypes.array,
};
