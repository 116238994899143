import { Box, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../auth';

const RequireAuth = ({ children }) => {

    const auth = useAuth();
    const location = useLocation();

    console.log(`Require Auth checking user: ${JSON.stringify(auth.user)} isLoading: ${auth.isLoading}`);

    if (auth.isLoading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="50vh"
            >
                <CircularProgress />
            </Box>
        )
    }

    if (!auth.user) {
        return <Navigate to="/register" replace state={{ from: location }} />;
    }

    return children;

};

RequireAuth.propTypes = {
    children: PropTypes.node
}

export default RequireAuth;